import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./FullHeightSection.scss";

gsap.registerPlugin(ScrollTrigger);

function FullHeightSection(props) {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const containers = section.querySelectorAll('.full-height-section-container');

    containers.forEach((container) => {
      const heading = container.querySelector('h2');
      const content = container.querySelector('p');

      // Split text into words
      const headingWords = heading.textContent.split(' ');
      const contentWords = content.textContent.split(' ');

      // Clear the original content
      heading.textContent = '';
      content.textContent = '';

      // Wrap each word in a span
      headingWords.forEach((word) => {
        const span = document.createElement('span');
        span.textContent = word + ' ';
        span.style.opacity = 0;
        heading.appendChild(span);
      });

      contentWords.forEach((word) => {
        const span = document.createElement('span');
        span.textContent = word + ' ';
        span.style.opacity = 0;
        content.appendChild(span);
      });

      // Create the animation
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: "top 60%", // Start when the top of the container is 60% from the top of the viewport
          end: "40% center", // End when 40% of the container is at the center of the viewport
          scrub: true,
        }
      });

      // Animate each word
      tl.to(heading.children, {
        opacity: 1,
        duration: 0.5,
        stagger: 0.1
      }).to(content.children, {
        opacity: 1,
        duration: 0.5,
        stagger: 0.05
      }, "-=0.5"); // Start slightly before the heading animation finishes
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [props.data]); // Re-run the effect if the data changes

  return (
    <div
      ref={sectionRef}
      className="parent full-height-section-parent"
      // style={{ background: ${props.background} }}
    >
      {props.data.map((item, index) => (
        <div className="container full-height-section-container" key={index}>
          <p className="curious">{item.heading}</p>
          <h2 >{item.content}</h2>
          
        </div>
      ))}
    </div>
  );
}

export default FullHeightSection;