import React from "react";
import Shero from "../../components/servicehero/Shero";
import step1 from "../../assets/service/steps/first.webp";
import step2 from "../../assets/service/steps/second.webp";
import step3 from "../../assets/service/steps/third.webp";
import step4 from "../../assets/service/steps/four.webp";
import Sopd from "../../components/sopd/Sopd";
import Scategory from "../../components/s_category/Scategory";
import Steps from "../../components/steps/Steps";
import "./Service.scss";
import { Helmet } from "react-helmet";
function Service() {
  const data1 = [
    {
      heading: "World-Class Care, Anywhere, Anytime with Global OPD",
      content: ` Not from India? No problem! Access top-tier healthcare at affordable rates. Our virtual clinics connect you with doctors worldwide, in your preferred language.`,
    },
  ];

  const data2 = [
    {
      heading: "Second Opinions to Ensure Your Health Gets the Best Care",
      content: ` Just as you'd get a second opinion for your car, why not for your health? Whether it's cancer, tuberculosis, or chronic disease management, AxonCare provides expert insights on diagnoses, prognoses, and more from our network of specialists.
`,
    },
  ];

  const service_data = [
    {
      image: step1,
      description: "Download and register or log in to the AxonCare app.",
    },
    {
      image: step2,
      description: "Search for a doctor or specialist under Book Appointments.",
    },
    {
      image: step3,
      description: "Select doctor/specialist, clinic, and appointment mode.",
    },
    {
      image: step4,
      description:
        "Choose a date/time, make payment, and confirm your booking.",
    },
  ];

  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>
          AxonCare: Your Personalized Health Sanctuary Anytime, Anywhere
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Experience world-class healthcare with AxonCare. Manage your health effortlessly with our user-friendly app, connect with specialists, and access healthcare services anytime, anywhere."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare, healthcare app, personalized health, book appointments, virtual clinics, global healthcare, corporate healthcare, elderly care, school health services, community health"
        />
      </Helmet>
      {/* Service Hero Section 
    and about Section */}
      <Shero />

      {/* -App Download Steps--- */}
      <Steps />
      {/* World Classs and Second Opnions Section */}
      <Sopd />

      {/* Service Category  */}
      <Scategory />
    </>
  );
}

export default Service;
