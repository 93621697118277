import React, { useRef, useEffect } from "react";
import "./Doctors.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Doctors(props) {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-out-quart", // Animation easing
    });
  }, []);

  return (
    <>
      <div
        className="parent doctor-parent"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
        style={{ background: `${props.background}` }}
      >
        <div className="container doctor-container">
          <Swiper
            spaceBetween={30}
            slidesPerView={3} // Adjust how many slides you want visible at a time
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },

              600: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1150: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="doctor-swiper"
          >
            {props.data.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                {/* Add SwiperSlide here */}
                <div className="main-card">
                  <div
                    className="top-card bg-img-cover"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <div className="bottom-card">
                    <h3>{item.heading}</h3>
                    <p>{item.description}</p>
                    <p style={{ color: "#f1b821", fontWeight: "600" }}>
                      {item.designation}
                    </p>
                    {item.btn && <Link className="cta-btn">{item.btn}</Link>}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Doctors;
