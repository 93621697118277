import React from "react";
import "./downloadbtn.scss";
import google from "../../assets/google.webp";
import apple from "../../assets/apple.webp";
const DownloadBtn = ({ border }) => {
  const btns = [
    {
      title: "Get It On",
      desc: "Google Play",
      image: google,
      link: "https://play.google.com/store/apps/details?id=com.axoncare&pli=1",
    },
    {
      title: "Download on the",
      desc: "App Store",
      image: apple,
      link: "https://apps.apple.com/in/app/axoncare/id6544802987",
    },
  ];
  return (
    <div className="download-btn-parent-box">
      {btns.map((data, index) => {
        return (
          <a
            className="download-btn-parent"
            key={index}
            href={data.link}
            target="__blank"
            style={{ border: border ? "1px solid #dadada" : "" }}
          >
            <img src={data.image} alt="" />
            <div className="down-btn-box">
              <p className="dow-title">{data.title}</p>
              <p className="dow-desc">{data.desc}</p>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default DownloadBtn;
