import React from "react";
import "./career.scss";
import Before_footer from "../../components/Before-footer/Before_footer";
import { Helmet } from "react-helmet";
const Career = () => {
  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>Join AxonCare: Revolutionizing Healthcare Together</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Join our team at AxonCare and be part of the revolution in healthcare. Together, we can make a difference by providing accessible and personalized healthcare solutions globally."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare, healthcare careers, join our team, revolutionizing healthcare, healthcare jobs, make a difference, healthcare solutions, global healthcare"
        />
      </Helmet>
      <div className="career">
        <Before_footer
          title="Join AxonCare Network"
          description="We're revolutionising healthcare. Join our team to make a difference."
          buttonText="Contact Us"
          buttonLink="/contact"
        />
      </div>
    </>
  );
};

export default Career;
