import React from "react";
import "./FourCard.scss";
import image from "../../assets/boy.webp";

function FourCard({data}) {
  

  return (
    <>
      <div className="parent four-card-parent">
        <div className="container four-card-container">
          {data.map((item, index) => (
            <div className="main-card" key={index} data-aos="fade-up" data-aos-delay={index *500 } >
              <div
                className="top-card bg-img-cover"
                style={{ background: `url(${item.img})` }}
              ></div>
              <div className="bottom-card">
                <h3 className="heading">
                <span> {item.head}</span> {item.heading}
                </h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FourCard;
