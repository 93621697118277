import React, { useState, useRef, useEffect } from "react";
import Before_footer from "../../components/Before-footer/Before_footer";
import "./Axoncare.scss";
import ReactPlayer from "react-player";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import video2 from "../../assets/axoncare/axoncare.webm";
import mobileVideo from "../../assets/axoncare/mobile.webm"; // Add your mobile-specific video here
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

const Axoncare_clinic = () => {
  const [isMobile, setIsMobile] = useState(false);
  const videoBoxRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    // Function to check the screen width
    const checkScreenWidth = () => {
      if (window.innerWidth <= 700) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Check screen width on load
    checkScreenWidth();

    // Add a resize event listener
    window.addEventListener("resize", checkScreenWidth);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  // GSAP animation for video section
  useEffect(() => {
    gsap.fromTo(
      videoBoxRef.current,
      { scale: 0.5, borderRadius: "10px" },
      {
        scale: 1,
        borderRadius: "0px",
        scrollTrigger: {
          trigger: videoBoxRef.current,
          start: "top 80%",
          end: "bottom 80%",
          scrub: true,
          markers: false,
        },
      }
    );
  }, []);

  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>AxonCare: Multispeciality Virtual Clinic for Smart Care</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Experience world-class healthcare with AxonCare Clinic. Access top-tier specialists globally and enjoy comprehensive services from the comfort of your home with our innovative Anytime Health Kiosk."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare, multispeciality clinic, virtual healthcare, Anytime Health Kiosk, specialist consultations, healthcare innovation, telemedicine, comprehensive healthcare, book consultation"
        />
      </Helmet>
      <div className="axoncare-parent parent">
        <div className="axoncare-container container">
          <h2 data-aos="fade-up">
            AxonCare Multispeciality <span>Virtual Clinic</span> For Smart Care
          </h2>
          <p data-aos="fade-up" data-aos-delay="200">
            Imagine healthcare like never before, with world-class facilities at
            your fingertips. No more running between specialists and hospitals—
            AxonCare Clinic is your one-stop solution for high-tech,
            comprehensive healthcare. AxonCare Clinic connects you with top-tier
            specialists from around the world, making global healthcare
            expertise just a tap away.
          </p>
        </div>

        <div className="axoncare-video-parent parent">
          <div className="axoncare-video container" ref={videoBoxRef}>
            <ReactPlayer
              className="v-box"
              url={isMobile ? mobileVideo : video2} // Conditionally load the video based on screen size
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
              controls={false}
            />
          </div>
        </div>

        <div className="twocol-parent parent">
          <div className="container twocol-container">
            <div className="twocol-left">
              <h2 className="heading" data-aos="fade-up">
                Anytime Health Kiosk - Our Core Invention
              </h2>
              <p data-aos="fade-up" data-aos-delay="200">
                Our Anytime Health Kiosk is a groundbreaking health-tech
                innovation at the heart of our healthcare service. Equipped with
                integrated point-of-care testing devices, it provides patients
                and doctors with a near-physical experience in a virtual
                setting. The design emphasises the crucial role of human touch
                in merging innovation with technology.
              </p>

              <div className="check-box-parent">
                <div
                  className="check-box-container"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="check-box1">
                    <div className="check-mark">
                      <IoIosCheckmarkCircleOutline />
                    </div>
                    <p>Specialist Virtual Consultations</p>
                  </div>
                  <div className="check-box2">
                    <div className="check-mark">
                      <IoIosCheckmarkCircleOutline />
                    </div>
                    <p>Preliminary Physical Examinations</p>
                  </div>
                  <div className="check-box3">
                    <div className="check-mark">
                      <IoIosCheckmarkCircleOutline />
                    </div>
                    <p>Instant Test Results</p>
                  </div>
                  <div className="check-box4">
                    <div className="check-mark">
                      <IoIosCheckmarkCircleOutline />
                    </div>
                    <p>Direct Data Connectivity</p>
                  </div>
                </div>
              </div>
              <div
                className="address-col "
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="addressleft">
                  <div className="icon">
                    <FaMapMarkerAlt />
                  </div>

                  <a
                    href="https://maps.app.goo.gl/mcf9PB4tgZ2vRaeD7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h4 className="heading-address">Bavdhan Clinic</h4>
                    <p>AxonCare Clinic, Kapil Zenith, Bavdhan</p>
                  </a>
                </div>
                <div className="addressright">
                  <div className="icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="content">
                    <a
                      href="https://maps.app.goo.gl/wywvyLD5mQxPsgmc6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4 className="heading-address">Mumbai Clinic</h4>
                      <p>AxonCare Clinic, Mumbai</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="twocol-right bg-img-cover" data-aos="fade-up"></div>
          </div>
        </div>

        {/* <Before_footer
          title="Your One-stop Solution"
          description="Cough, cold or body ache? Book a consultation with our doctors from the comfort of your home now."
          buttonText="Know More"
          buttonLink="/contact"
         
        /> */}

        <div className="before-footer-parent parent">
          <div className="before-footer-container container">
            <p className="section-ind curious" data-aos="fade-up">
              Your One-stop Solution
            </p>

            <h2 className="heading" data-aos="fade-up" data-aos-delay="200">
              Book a consultation with our doctors from the comfort of your home
              now.
            </h2>

            <Link
              to="/contact"
              className="cta-btn"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              Know More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Axoncare_clinic;
