import React, { useEffect } from "react";
import "./contact.scss";
import { MdOutlineMail } from "react-icons/md";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import ContactForm from "../../components/contactcomp/ContactForm";
import { Helmet } from "react-helmet";
function Contact() {
  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>
          Contact AxonCare: Partner with Us in Healthcare Innovation
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Get in touch with AxonCare's offices across the USA, UK, and India. Interested in partnering with a leading healthcare innovator? Join the AxonCare network today."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare, contact us, healthcare innovation, partner with us, USA office, UK office, India office, healthcare partnerships, AxonCare network"
        />
      </Helmet>
      <div className="contact-parent parent">
        <div className="contact-container container">
          <div className="contact-left contact-body">
            {/* card 1 */}
            <div className="contact-card">
              <div className="flag-box">
                <div className="flag-content">USA</div>
                <div className="flag bg-img-cover dubai"></div>
              </div>

              <div className="heading custom-heading">USA Office :</div>
              <a href="tel:+14086936337​" className="contact-card-box">
                <span>
                  <IoCallOutline />
                </span>{" "}
                <div className="contact-info">
                  <p>+1 408 693 6337​</p>
                </div>
              </a>
              <a
                href="https://maps.app.goo.gl/wUvDhkbhLCnKCk4TA"
                className="contact-card-box"
                target="__blank"
              >
                <span>
                  <IoLocationOutline />
                </span>{" "}
                <div className="contact-info">
                  <div className="heading custom-heading2">
                    California Office :{" "}
                  </div>
                  <p>505 Cento Ct, Pleasanton, CA 94566​</p>
                </div>
              </a>
              <a
                href="https://maps.app.goo.gl/CVWvUKyAZnHMcXpX6"
                className="contact-card-box"
                target="__blank"
              >
                <span>
                  <IoLocationOutline />
                </span>{" "}
                <div className="contact-info">
                  <div className="heading custom-heading2">
                    Delaware Office :{" "}
                  </div>
                  <p>
                    3911 Concord Pike #8030,
                    <br /> Wilmington, Delaware,19803
                  </p>
                </div>
              </a>
            </div>

            {/* card 2 */}
            <div className="contact-card">
              <div className="flag-box">
                <div className="flag-content">UK</div>
                <div className="flag bg-img-cover uk"></div>
              </div>
              <div className="heading custom-heading">UK Office :</div>
              <a href="tel:+44 203 411 3999" className="contact-card-box">
                <span>
                  <IoCallOutline />
                </span>{" "}
                <div className="contact-info">
                  <p>+44 203 411 3999</p>
                </div>
              </a>
              <a
                href="https://maps.app.goo.gl/haYyLsWvNbWU5eoE7"
                className="contact-card-box"
                target="__blank"
              >
                <span>
                  <IoLocationOutline />
                </span>{" "}
                <div className="contact-info">
                  <p>20 Langland Drive, Pinner HA5 4SA​</p>
                </div>
              </a>
            </div>

            {/* card 3 */}
            <div className="contact-card">
              <div className="flag-box">
                <div className="flag-content">India</div>
                <div className="flag bg-img-cover india"></div>
              </div>
              <div className="heading custom-heading">India Office :</div>
              <a href="tel:+91 9820012037​" className="contact-card-box">
                <span>
                  <IoCallOutline />
                </span>{" "}
                <div className="contact-info">
                  <p>+91 9820012037​</p>
                </div>
              </a>
              <div className="contact-card-box">
                <span>
                  <IoLocationOutline />
                </span>{" "}
                <a
                  href="https://maps.app.goo.gl/aScSECScUZRB3GLL7"
                  className="contact-info"
                  target="__blank"
                >
                  <div className="heading custom-heading2">
                    Development Centre Pune :{" "}
                  </div>
                  <p>
                    403, 4th Floor, Kapil Zenith IT Park, <br /> Bavdhan, Pune
                    411021​
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="contact-right contact-body">
            <div>
              <ContactForm
                heading="We’d Love To Hear From You"
                description="Interested in partnering with a leading healthcare innovator? Join the AxonCare network."
              />
            </div>

            {/*  */}
            <div className="contact-card">
              <a
                href="mailto:info@axonichealth.com"
                className="contact-card-box"
              >
                <span>
                  <MdOutlineMail />
                </span>{" "}
                <div className="contact-info">
                  <div className="heading custom-heading">Email :</div>
                  <p>info@axonichealth.com</p>
                </div>
              </a>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
