import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer/Footer";
import Navbar from "./components/header/Navbar";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import "./fixes.scss";
import About from "./pages/about/About";
import Axoncare_clinic from "./pages/axoncare/Axoncare_clinic";
import Career from "./pages/career/Career";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Service from "./pages/services/Service";
import Error from "./pages/notfound/Error";
import Blogs from "./pages/blogs/Blogs";
import BlogDetailPage from "./pages/blogdetail/BlogDetail";
import Tools from "./components/Tools/Tools";
import ReactGA from "react-ga4";
import AxoncareNetwork from "./pages/axoncarenetwork/AxoncareNetwork";
// Install Google Analytics
ReactGA.initialize("G-PJ1WHYN248");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Tools />
        {/* <LocomotiveScrollHandler/> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/axoncareClinic" element={<Axoncare_clinic />} />
          <Route path="/axoncareNetwork" element={<AxoncareNetwork />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetailPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Service />} />
          <Route path="/*" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
