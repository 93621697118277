import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./Scategory.scss";

function Scategory() {
  return (
    <>
      <div className="scategory-parent parent">
        {/* <div className="scategory-container container"> */}
        <Swiper
          spaceBetween={20}
          centeredSlides={false}
          slidesPerView={5}
          autoplay={{
            delay: 2500,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
           
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
            },

            600: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1500: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="catSwiper"
        >
          {/* --------slide1------------ */}
          <SwiperSlide>
            <div className="catergory-card cimg1 bg-img-cover">
              <div className="overlay"></div>
              <div className="card-content-box">
              <div className="category-card-top">
                <h3 className="sc-heding">Corporate Healthcare</h3>
                </div>


                <div className="category-card-bottom">
                <p className="cat-p">
                  Boost employee health and productivity with on-site specialist
                  consultations, health monitoring, and mental health support.
                </p>
                <a href="/contact">
                  <button className="cta-btn cat-btn">Enquire Now</button>
                </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* --------slide2------------ */}
          <SwiperSlide>
            <div className="catergory-card cimg2 bg-img-cover">
              <div className="overlay"></div>
              <div className="card-content-box">
                <div className="category-card-top child2">
                <h3 className="sc-heding">Labs and Diagnostics</h3>
                </div>
                <div className="category-card-bottom">
                    
                <p className="cat-p">
                  Partner with AxonCare to offer patients instant doctor
                  consultations alongside your lab and diagnostic services,
                  providing a comprehensive healthcare solution.
                </p>
                <a href="/contact">
                  <button className="cta-btn cat-btn">Enquire Now</button>
                </a>
                 </div>
              
              </div>
            </div>
          </SwiperSlide>
          {/* --------slide3------------ */}
          <SwiperSlide>
            <div className="catergory-card cimg3 bg-img-cover">
              <div className="overlay"></div>
              <div className="card-content-box">
                <div className="category-card-top">
                  <h3 className="sc-heding">Efficient Elderly Care</h3>
                </div>
                <div className="category-card-bottom">
                  <p className="cat-p">
                    Deliver convenient, continuous care for the elderly with
                    on-site health monitoring, specialist access, and real-time
                    insights for family members.
                  </p>
                  <a href="/contact">
                    <button className="cta-btn cat-btn">Enquire Now</button>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* --------slide4------------ */}
          <SwiperSlide>
            <div className="catergory-card cimg4 bg-img-cover">
              <div className="overlay"></div>
              <div className="card-content-box">
              <div className="category-card-top">
                <h3 className="sc-heding">School Health Services</h3>
                </div>
                <div className="category-card-bottom">
                <p className="cat-p">
                  Ensure the well-being of students with on-campus health
                  clinics, early detection of health issues, and peace of mind
                  for parents.
                </p>
                <a href="/contact">
                  <button className="cta-btn cat-btn">Enquire Now</button>
                </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          
          {/* --------slide5------------ */}
          <SwiperSlide>
            <div className="catergory-card cimg5 bg-img-cover">
              <div className="overlay"></div>
              <div className="card-content-box">
              <div className="category-card-top">
                <h3 className="sc-heding">Community Health </h3>
                </div>
                <div className="category-card-bottom">
                <p className="cat-p">
                  Bring world-class healthcare to your doorstep with on-site
                  clinics, offering easy access to specialists and comprehensive
                  health services.
                </p>
                <a href="/contact">
                  <button className="cta-btn cat-btn">Enquire Now</button>
                </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* </div> */}
    </>
  );
}

export default Scategory;
