import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import img from "../../assets/logo/logo.webp";
import imgw from "../../assets/logo/logo-white.webp";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Twirl as Hamburger } from "hamburger-react";
import { IoCallOutline } from "react-icons/io5";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  // Refs for GSAP animation
  const logoRef = useRef(null);
  const linksRef = useRef([]);
  const ctaRef = useRef(null);

  // Navbar links and submenu
  const navbarLinks = [
    { name: "", path: "" },
    {
      name: "About",
      submenu: [
        { name: "Blogs", path: "/blogs" },
        { name: "Career", path: "/career" },
        { name: "Contact", path: "/contact" },
      ],
    },
    { name: "Services", path: "/services" },
    { name: "AxonCare Clinic", path: "/axoncareClinic" },
    { name: "AxonCare Network", path: "/axoncareNetwork" },
  ];

  const ctaText = "1800 210 1999";

  // GSAP Animation on page load
  useEffect(() => {
    gsap.from(logoRef.current, {
      x: -200,
      opacity: 0,
      duration: 1,
      ease: "power3.out",
    });

    gsap.from([...linksRef.current, ctaRef.current], {
      opacity: 0,
      y: -50,
      duration: 0.8,
      ease: "power3.out",
      stagger: 0.2,
    });
  }, []);

  const location = useLocation();

  // Handle scroll state
  useEffect(() => {
    if (location.pathname !== "/service") {
      const handleScroll = () => {
        setScrolled(window.scrollY > 60);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location.pathname]);

  

  return (
    <div className={scrolled ? "navbar-parent parent" : "navbar-parent parent scrolled-navbar"}>
      <div className="navbar-container container">
        {/* Logo */}
        <Link to="/" className="logo-img">
          <img src={img} alt="Logo" ref={logoRef} />
        </Link>


        {/* Dynamic Desktop Links with Submenu */}
        <div className="links-box">
          {navbarLinks.map((link, index) => (
            link.submenu ? (
              <div className="submenu" key={index}>
                <div className="submenu-heading">
                  <Link  to="/about" ref={(el) => (linksRef.current[index] = el)}> {link.name} <span className="rotate-span"><MdKeyboardArrowDown /></span></Link>
                  <div className="submenu-links">
                    {link.submenu.map((subLink, subIndex) => (
                      <Link key={subIndex} to={subLink.path}>
                        {subLink.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <Link
                key={index}
                to={link.path}
                className={scrolled ? "scrolled-hover" : ""}
                ref={(el) => (linksRef.current[index] = el)}
              >
                {link.name}
              </Link>
            )
          ))}
        </div>

        {/* Hamburger for Mobile */}
        <div className="hamburger-box">
          <Hamburger toggled={isOpen} toggle={setOpen} color={isOpen ? "#ffffff" : "#000000"} />
        </div>

        {/* Dynamic CTA Button */}
        {ctaText && (
          <div className="cta-box">
            <a href="tel:+18002101999" className="cta-btn" ref={ctaRef}>
              <div className="header-icon"><IoCallOutline /></div>{ctaText}
            </a>
          </div>
        )}
      </div>

      {/* Dynamic Mobile Menu */}
      {isOpen && (
        <div className="mobile-navbar-parent">
          <div className="mobile-navbar-container">
            <Link to="/" className="mobile-logo-img" onClick={() => setOpen(false)}>
              <img src={imgw} alt="Logo" className="mobile-logo" />
            </Link>

            {/* Dynamic Mobile Links */}
            <div className="mobile-menu-links">
              {navbarLinks.map((link, index) => (
                link.submenu ? (
                  <div className="phone-menu" key={index}>
                    <div className="phone-menu-heading" >
                      <Link to="/about" onClick={() => setOpen(false)}>{link.name} </Link><span className="handle-hover"  onClick={() => setMenuOpen(!menuOpen)}><MdKeyboardArrowDown /></span>
                    </div>

                    {/* Toggleable Submenu */}
                    <div className={menuOpen ? "phone-menu-links" : "phone-menu-links-active"}>
                      {link.submenu.map((subLink, subIndex) => (
                        <Link key={subIndex} to={subLink.path} onClick={() => setOpen(false)} >
                          {subLink.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link key={index} to={link.path} onClick={() => setOpen(false)}>
                    {link.name}
                  </Link>
                )
              ))}
            </div>

            {/* Dynamic Mobile CTA Button */}
            {ctaText && (
              <a href="tel:+18002101999" className="mobile-cta-box">
                <button className="cta-btn">
                  <div className="header-icon"><IoCallOutline /></div>{ctaText}
                </button>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
