import React from "react";
import "./footer.scss";
import logow from "../../assets/logo/logow.png";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <div className="footer-parent parent">
        <div className="footer-container container">
          <div className="footer-top">
            <div className="footer-left-box">
              <div className="footer-logo">
                <img src={logow} alt="" />
              </div>
              <p className="company-about">
                AxonCare, a service brand by Axonic, is a global healthcare
                network that connects doctors and patients worldwide through
                cutting-edge technology solutions.
              </p>
            </div>

            <div className="footer-right-box">
              <div className="footer-icon-box">
                <a
                  target="__blank"
                  href="https://x.com/i/flow/login?redirect_after_login=%2FAxonicH86117"
                  className="twitter footer-link"
                >
                  <BsTwitterX />
                </a>
                <a
                  target="__blank"
                  href="https://www.facebook.com/axoncarebyaxonichealth/"
                  className="facebook footer-link"
                >
                  <FaFacebookF />
                </a>
                <a
                  target="__blank"
                  href="https://www.linkedin.com/company/axoncare-by-axonichealth/"
                  className="Linkedin footer-link"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  target="__blank"
                  href="https://www.instagram.com/axonichealth/"
                  className="instagram footer-link"
                >
                  <FaInstagram />
                </a>
              </div>

              <div className="footer-adresses">
                <a href="tel:+14086936337" className="footer-mail-box">
                  <span className="footer-icon">
                    <IoCallOutline />
                  </span>
                  <p> +1 408 693 6337</p>
                </a>
                <a
                  href="mailto:info@axonichealth.com"
                  className="footer-mail-box"
                >
                  <span className="footer-icon">
                    <MdOutlineEmail />
                  </span>
                  <p>info@axonichealth.com</p>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>Copyright © 2024 Axonic All rights reserved</p>
            <p>
              Designed by{" "}
              <a href="https://diwise.uk/" style={{ color: "var(--accent-yellow)" }} target="__blank">
                DIwise
              </a>{" "}
            </p>
          </div>
        </div>
      </div>

    </>
  );
};

export default Footer;
