import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./Threecard.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AOS from "aos";
import "aos/dist/aos.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const Threecard = ({ cards, slides, showButton, buttonText, buttonLink,change }) => {
  useEffect(() => {
    // Initialize AOS

    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-out-quart", // Animation easing
    });
  }, []);
  return (
    <div className="three-card-parent parent">
      <div className="card-body">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1, // 1 slide for small screens
            },
            768: {
              slidesPerView: 2, // 2 slides for medium screens
            },
            1024: {
              slidesPerView: `${slides ? "3" : "2"}`, // 3 slides for larger screens
            },
          }}
        >
          {cards.map((card, index) => (
            <SwiperSlide
              key={index}
              className="card bg-img-cover"
              style={{ backgroundImage: `url(${card.image})` }}
            >
              {/* <motion.div
                
             
                  initial={{ opacity: 0, scale: 0.8 }} // Starting state
                  animate={{ opacity: 1, scale: 1 }} // Animation to perform
                  transition={{ duration: 0.5, delay: index * 0.2 }} // Animation timing and delay
                > */}
              <div className="overlay">
                <div className="content-div">
                  <h3 className="card-heading">{card.heading}</h3>
                  <p className="card-desc">{card.description}</p>
                   {/* Conditionally render the button based on showButton prop */}
                   {showButton && (
                    <Link className="cta-btn" to={buttonLink || "/contact"} >
                      {buttonText || "Know More"}
                    </Link>
                  )}

                  {/* Conditionally render elements based on the `change` prop */}
                  {/* {change && (
                    <div className="additional-content">
                     
                    </div>
                  )} */}
                </div>

                {/* <Link className="cta-btn"> Know More </Link> */}
              </div>
              {/* </motion.div> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Threecard;
