import "./Servicehero.scss";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import DownloadBtn from "../downloadbtns/DownloadBtn";

function Shero() {
  const checkboxesRef = useRef([]);

  const checkboxes = [
    {
      
      checkbox: "Centralise Health Data",
    },
    {
      checkbox: "Access Healthcare Anytime",
    },
    {
      checkbox: "Connect with Doctors Easily",
    },
    {
      checkbox: "Secure Data Sharing",
    },
  ];

  return (
    <>
      <div className="servicehero-parent parent">
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper1"
        >
          <SwiperSlide>
            <div className="slide slide-img2 bg-img-cover">
              <div className="overlay"></div>
              <div className="slide-text-container container">
                <h1 className="slide-heading">
                  Manage your health from the comfort of your couch in just a
                  few clicks!
                </h1>
                <DownloadBtn border={true}/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide slide-img3 bg-img-cover">
              <div className="overlay"></div>
              <div className="slide-text-container container">
                <h1
                  className="slide-heading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                >
                  No more scrambling for appointments with multiple specialists.
                </h1>
                <DownloadBtn border={true}/>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide slide-img1 bg-img-cover">
              <div className="overlay"></div>
              <div className="slide-text-container container">
                <h1
                  className="slide-heading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                >
                  Never lose your family’s medical history and paperwork again!
                </h1>
                <DownloadBtn border={true}/>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* ////--------Service About Section------------- 
            and scss in the Service.scss*/}

      <div className="parent service-twocol-parent">
        <div className="container service-twocol-container">
          <div className="service-twocol-left">
            <h2 className="heading" data-aos="fade-up" data-aos-delay="100">
              <span>Your Health</span> Sanctuary in Your Pocket
            </h2>
            <p
              data-aos="fade-up"
              data-aos-delay="200"
              className="two-col-description"
            >
              AxonCare transforms your smartphone into a personalised health
              sanctuary. With everything you need to feel empowered about your
              well-being right at your fingertips, you’ll be in control.
            </p>

            {/* Dynamic Checkbox Rendering */}
            <div className="service-check-box-parent">
              {checkboxes?.map((checkboxItem, index) => (
                <div
                  key={index}
                  className="service-check-box-container"
                  ref={(el) => (checkboxesRef.current[index] = el)}
                  data-aos="fade-up"
                  data-aos-delay={`${500 + index * 100}`}
                >
                  <div className="check-mark">
                    <IoIosCheckmarkCircleOutline />
                  </div>
                  {/* Accessing the checkbox property */}
                  <p className="service-checkbox-title">
                    {checkboxItem.checkbox}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* -------------Right Image Div ------*/}
          <div
            className="service-twocol-right bg-img-cover"
            data-aos="fade-up"
            data-aos-delay="600"
          ></div>
        </div>
      </div>
    </>
  );
}

export default Shero;
