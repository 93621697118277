import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./twocol.scss";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom"; // Use "next/link" if using Next.js

const TwoCol = ({
  reverse,
  background,
  secondtitle,
  buttons,
  checkboxes,
  title,
  image,
  description,
  span,
  span3,
  span2,
  curious,
}) => {
  const buttonsRef = useRef([]);
  const checkboxesRef = useRef([]);

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-out-quart", // Animation easing
    });
  }, []);

  return (
    <div
      className="parent twocol-parent scroll-area"
      style={{ background: background ? "var(--accent-yellow-light)" : "" }}
    >
      {
        curious &&  <p
        className="curious two-col-curious"
        data-aos="fade-up"
        data-aos-delay="00"
        data-aos-duration="1000"
      >
        {curious}
      </p>
      }
       
      <div
        className="container twocol-container"
        style={{ flexDirection: reverse ? "row-reverse" : "" }}
      >
      
        <div className="twocol-left">
          {/* Apply fade-up with staggered delay */}
          
          <h2 className="heading" data-aos="fade-up" data-aos-delay="100">
            {" "}
            <span>{span}</span>

            {title} <span>{span3}</span> {span2}
          </h2>
          <span data-aos="fade-up" data-aos-delay="150">{secondtitle}</span>
          <p
            data-aos="fade-up"
            data-aos-delay="200"
            className="two-col-description"
          >
            {description}
          </p>

          {/* Dynamic Button Rendering */}
          <div className="button-box">
            {buttons?.map((button, index) => (
              <Link
                key={index}
                to={button.path}
                ref={(el) => (buttonsRef.current[index] = el)}
                className="cta-btn"
                data-aos="fade-up"
                data-aos-delay={`${300 + index * 100}`} // Stagger effect
              >
                {button.text}
              </Link>
            ))}
          </div>

          {/* Dynamic Checkbox Rendering */}
          <div className="check-box-parent">
            {checkboxes?.map((checkbox, index) => (
              <div
                key={index}
                className="check-box-container"
                ref={(el) => (checkboxesRef.current[index] = el)}
                data-aos="fade-up"
                data-aos-delay={`${500 + index * 100}`} // Stagger effect
              >
                <div className="check-mark">
                  <IoIosCheckmarkCircleOutline />
                </div>
                <p className="checkbox-title">{checkbox}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Right Image Div */}
        <div
          className="twocol-right bg-img-cover"
          data-aos="fade-up"
          data-aos-delay="600" // Animate the image after the other elements
          style={{ background: `url(${image})` }}
        ></div>
      </div>
    </div>
  );
};

export default TwoCol;
