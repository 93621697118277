import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./Steps.scss";
import step1 from "../../assets/service/steps/first.webp";
import step2 from "../../assets/service/steps/second.webp";
import step3 from "../../assets/service/steps/third.webp";
import step4 from "../../assets/service/steps/four.webp";
import { IoCallOutline } from "react-icons/io5";

function Steps() {
  const service_data = [
    {
      image: step1,
      heading: "1",
      description:
        "Download and register or log in to the User-friendly AxonCare app.",
    },
    {
      image: step2,
      heading: "2",
      description: "Search for a doctor or specialist under Book Appointments.",
    },
    {
      image: step3,
      heading: "3",
      description: "Select doctor/specialist, clinic, and appointment mode.",
    },
    {
      image: step4,
      heading: "4",
      description:
        "Choose a date/time, make payment, and confirm your booking.",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-quart",
    });
  }, []);
  return (
    <>
      <div
        className="parent steps-parent bg-img-cover"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
      >
        <div className="container steps-container">
        <h2  className='step-heading'><span>4 Simple Steps</span> to Book an Appointment with the AxonCare App
        </h2>
          <Swiper
            spaceBetween={30}
            slidesPerView={4}
            centeredSlides={false}
            autoplay={{
              delay: 250000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },

              730: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1150: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="steps-swiper"
          >
            {service_data.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                {/* Add SwiperSlide here */}
                <div className="smain-card">
                  <div
                    className="top-card bg-img-cover"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <div className="bottom-card">
                    <h3 className="step">
                      Steps <span>{item.heading}</span>
                    </h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <h2>Or <span>get in touch with us</span> and we will do it for you.
          </h2>
          <a href='tel:+18002101999' className="cta-btn"><div className="header-icon"><IoCallOutline/></div> 1800 210 1999</a>
        </div>
      </div>
    </>
  );
}

export default Steps;
