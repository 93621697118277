import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Before_footer.scss";
import { Link } from "react-router-dom";
import { IoCallOutline } from "react-icons/io5";

const Before_footer = ({
  title,
  description,
  buttonText,
  buttonLink,
  call,
}) => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once
    });
  }, []);

  return (
    <div className="before-footer-parent parent">
      <div className="before-footer-container container">
        <p className="section-ind curious" data-aos="fade-up">
          {title}
        </p>

        <h2 className="heading" data-aos="fade-up" data-aos-delay="200">
          {description}
        </h2>

        {buttonText && buttonLink && !call &&(
          <Link
            to={buttonLink}
            className="cta-btn"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {buttonText}
          </Link>
        )}

        {call && (
          <a
            href="tel:18002101999"
            className="cta-btn"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <span>
              <IoCallOutline />
            </span>
            {buttonText}
          </a>
        )}
      </div>
    </div>
  );
};

export default Before_footer;
