import React, { useRef } from "react";
import "./home.scss";
import TwoCol from "../../components/twocol/TwoCol";
import Before_footer from "../../components/Before-footer/Before_footer";
import FourCard from "../../components/fourcard/FourCard";
import Threecard from "../../components/threecard/Threecard";

// emp image
import corporate from "../../assets/home/corporate.webp";
import elderly from "../../assets/home/elderly-care-facilities.webp";
import residential from "../../assets/GIF.gif";
import residential2 from "../../assets/home/residencial-socities.webp";

// img replace section
import long_waiting from "../../assets/home/long_waiting.webp";
import expensive_travel from "../../assets/home/expensive_travel.webp";
import scatter_medical_record from "../../assets/home/scatter_medical_record.webp";
import disconnected_care from "../../assets/home/disconnected_care.webp";

//same section image
import book_appointment from "../../assets/home/book_appoint.webp";
import Access_specialist from "../../assets/home/Access_specialist.webp";
import store_manage from "../../assets/home/store_manage.webp";
import doctor from "../../assets/home/doctor.webp";

import school from "../../assets/home/school-health.webp";
// cards image
import { Helmet } from "react-helmet";
import virtual from "../../assets/home/virtual-clinic.webp";
import axoncarenetwork from "../../assets/home/Axoncare-network.webp";
import ai_powered from "../../assets/home/ai-powered.webp";
import Testimonial from "../../components/testimonial/Testimonial";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DownloadBtn from "../../components/downloadbtns/DownloadBtn";

const Home = () => {
  const cardData = [
    {
      image: virtual,
      heading: "virtual clinics",
      delay: "1000",
      description:
        "Our virtual clinic is available anytime, anywhere through our Anytime Health Kiosk and the AxonCare app. You can connect with doctors globally, breaking down language and distance barriers.",
    },
    {
      image: axoncarenetwork,
      heading: "AxonCare network",
      delay: "1400",
      description:
        "With the AxonCare network, you can access healthcare professionals worldwide, ensuring top-quality multispeciality care. This means better consultations and more options for your health journey.",
    },
    {
      image: ai_powered,
      heading: "AI-powered technology",
      delay: "1800",
      description:
        "Our AI-powered technology adds a personal touch while offering cutting-edge care, making healthcare easier, more accessible, and affordable for you.",
    },
  ];

  const empData = [
    {
      img: corporate,
      heading: "Enterprises",
      head: "Corporate",
      text: "Boost employee health and productivity with our 24/7 kiosk, offering specialist consultations, daily monitoring, and mental health support.",
    },
    {
      img: elderly,
      heading: "Facilities",
      head: "Elderly Care",
      text: "Provide daily health monitoring and instant specialist access for elderly residents, reducing hospital visits and offering continuous care.",
    },
    {
      img: residential2,
      heading: "Societies",
      head: "Residential ",
      text: "Enjoy convenient healthcare with AxonCare clinics in your society, providing easy access to specialists, consultations, and prescriptions.",
    },
    {
      img: school,
      heading: " Health",
      head: "School",
      text: "Ensure early health detection and continuous monitoring for students with an AxonCare clinic, supporting better physical and mental development.",
    },
  ];

  const replaceSection = [
    {
      description: "Long waiting times for appointments.",
      image: long_waiting,
    },
    {
      description: "Expensive travel to specialist clinics.",
      image: expensive_travel,
    },
    {
      description: "Scattered medical records, delay in care.",
      image: scatter_medical_record,
    },
    {
      description: "Disconnected care, gap in overall health view.",
      image: disconnected_care,
    },
  ];

  const sameSectionData = [
    {
      description:
        "Book appointments with any speciality easily through our app.",
      image: book_appointment,
    },
    {
      description: "Access all your  specialists under one roof.",
      image: Access_specialist,
    },
    {
      description: "Store and manage your entire medical history in one place.",
      image: store_manage,
    },
    {
      description: "Doctors can access your records anytime for timely care.",
      image: doctor,
    },
  ];

  // logic

  const sectionRef = useRef(null);

  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>
          AxonCare: World-Class Virtual Healthcare at Your Fingertips
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="AxonCare revolutionizes healthcare with AI-powered virtual clinics. Easily book appointments, access specialists, manage medical records, and enjoy exclusive packages for corporates, elderly care, schools, and residential societies."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare, virtual healthcare, AI-powered healthcare, virtual clinics, healthcare app, book appointments, medical history management, corporate health packages, elderly care, residential healthcare, school health"
        />
      </Helmet>

      <div className="parent home-parent">
        <TwoCol
          reverse={false}
          background={false}
          title="Elevate the Way You Experience Healthcare"
          description="AxonCare puts world-class healthcare at your fingertips. Get the care you need, when you need it, wherever you are."
          buttons={[
            { text: "Live Secretary", path: "/live-secretary" },
            {
              text: " Know More",
              path: "/about",
            },
          ]}
          image={residential}
        />
        <div ref={sectionRef} className="parent full-height-section-parent">
          <div className="container full-height-section-container  ">
            <h2
              className="firs-text"
              data-aos="fade-up"
              data-aos-delay="00"
              data-aos-duration="1000"
            >
              Accessing healthcare today can be <span> overwhelming...</span>
            </h2>
          </div>
        </div>
        {/* <TwoColGsap /> */}
        {/* Replace This section start  */}

        <div
          className="parent same-section-parent "
          style={{ background: "var(--white)" }}
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1000"
        >
          <div className="container same-section-container">
            <h2>
              {" "}
              With Your <span>Traditional Healthcare</span>
            </h2>
            <Swiper
              spaceBetween={30}
              slidesPerView={4}
              centeredSlides={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1150: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="same-section-swiper"
            >
              {replaceSection.map((item, index) => (
                <SwiperSlide key={index}>
                  {" "}
                  <div className="same-section-main-card">
                    <div
                      className="same-section-top-card bg-img-cover"
                      style={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <div className="same-section-bottom-card">
                      <p>{item.description}</p>
                      {/* <Link to="/contact" className="card-btn">Know More</Link> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        {/* Replace This section end  */}

        {/* same section start */}

        <div
          className="parent same-section-parent same-section-parent2"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1000"
        >
          <div className="container same-section-container">
            <h2>
              {" "}
              <span>AxonCare</span> Evolves Your Healthcare Experience
            </h2>
            <Swiper
              spaceBetween={30}
              slidesPerView={4}
              centeredSlides={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1150: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="same-section-swiper"
            >
              {sameSectionData.map((item, index) => (
                <SwiperSlide key={index}>
                  {" "}
                  <div className="same-section-main-card">
                    <div
                      className="same-section-top-card bg-img-cover"
                      style={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <div className="same-section-bottom-card">
                      <p>{item.description}</p>
                      {/* <Link to="/contact" className="card-btn">Know More</Link> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <p className="custom-p">
              Experience the best in healthcare with AxonCare.{" "}
              <span>Download the AxonCare app now!</span>{" "}
            </p>

            <DownloadBtn />
          </div>
        </div>

        {/* same section end */}

        {/* <FullHeightSection data={data2} /> */}
        <div
          className="parent full-height-section-parent"
          style={{ background: "var(--white)" }}
        >
          <div className="container full-height-section-container">
            <p
              className="curious"
              data-aos="fade-up"
              data-aos-delay="00"
              data-aos-duration="1000"
            >
              Curious how we do it?
            </p>
            <h2
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              We've combined the power of <span> AI</span> with a{" "}
              <span> global network </span> of healthcare professionals to
              create a revolutionary chain of <span>virtual clinics.</span>
            </h2>
          </div>
        </div>
        <Threecard cards={cardData} slides={true} />
        {/* <FullHeightSection data={data3} /> */}

        {/* 2nd text section start  */}

        <div className="parent full-height-section-parent">
          <div className="container full-height-section-container">
            <p
              className="curious"
              data-aos="fade-up"
              data-aos-delay="00"
              data-aos-duration="1000"
            >
              And who do we care for?
            </p>
            <h2
              className="second-text"
              data-aos="fade-up"
              data-aos-delay="00"
              data-aos-duration="1000"
            >
              <span>Everyone.</span> We also offer exclusive packages for{" "}
              <span>corporate</span> employees, <span>residential</span>{" "}
              communities, <span>schools,</span> and <span>elderly care</span>{" "}
              facilities.
            </h2>
          </div>
        </div>

        {/* 2nd text section start  */}

        <FourCard data={empData} />
        <Testimonial />
        <Before_footer
          title="Connect With Us"
          description="Have Questions or Need Further Information?"
          buttonText=" 1800 210 1999"
          buttonLink="/contact"
          call={true}
        />
      </div>
    </>
  );
};

export default Home;
