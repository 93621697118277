import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ReactPlayer from 'react-player';
import './Testimonial.scss';
import video1 from '../../assets/testimonial/gaurav.webm';
import video2 from '../../assets/testimonial/deepali.webm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

function Testimonial() {
    const [playingIndex, setPlayingIndex] = useState(null); 

    const handlePlayPause = (index) => {
       
        if (playingIndex === index) {
            setPlayingIndex(null);
        } else {
            setPlayingIndex(index);
        }
    };

    const handleSlideChange = () => {
       
        setPlayingIndex(null);
    };

    return (
        <div className="testimonial-parent parent">
            <div className="testimonial-container container">
                <div className="testimonial-left">
                    <h2 className="testimonial-heading">
                        Experience AxonCare firsthand—listen to real <span>testimonials.</span>
                    </h2>
                </div>
                <div className="testimonial-right">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        centeredSlides={false}
                        pauseOnMouseEnter={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        onSlideChange={handleSlideChange} 
                        breakpoints={{
                            300: { slidesPerView: 1, spaceBetween: 20 },
                            500: { slidesPerView: 1, spaceBetween: 20 },
                            640: { slidesPerView: 1, spaceBetween: 10 },
                            768: { slidesPerView: 1, spaceBetween: 10 },
                            1024: { slidesPerView: 2, spaceBetween: 10 },
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="video-container">
                                <ReactPlayer
                                    className="v-box"
                                    url={video1}
                                    playing={playingIndex === 0} 
                                    width="100%"
                                    height="100%"
                                    controls={false}
                                    style={{ borderRadius: "20px", overflow: "hidden" }}
                                />
                                <div className="custom-controls">
                                    <button onClick={() => handlePlayPause(0)}>
                                        <FontAwesomeIcon 
                                            icon={playingIndex === 0 ? faPause : faPlay} 
                                            className="play-pause-icon"
                                        />
                                    </button>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="video-container">
                                <ReactPlayer
                                    className="v-box"
                                    url={video2}
                                    playing={playingIndex === 1} 
                                    width="100%"
                                    height="100%"
                                    controls={false}
                                />
                                <div className="custom-controls">
                                    <button onClick={() => handlePlayPause(1)}>
                                        <FontAwesomeIcon 
                                            icon={playingIndex === 1 ? faPause : faPlay} 
                                            className="play-pause-icon"
                                        />
                                    </button>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
