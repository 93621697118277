import React from "react";
import "./axoncarenetwork.scss";
import Threecard from "../../components/threecard/Threecard";
import TwoCol from "../../components/twocol/TwoCol";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// image
import globalimage from "../../assets/axoncarenetwork/globalimage.webp";
import anytime from "../../assets/axoncarenetwork/Artboard_3.webp";
import practise from "../../assets/axoncarenetwork/artbord_2.webp";
import artborad4 from "../../assets/axoncarenetwork/artboard_4.webp";
import patientFlow from "../../assets/axoncarenetwork/increased_patient_flow_webp.webp";
import global from "../../assets/axoncarenetwork/global.webp";
import ourservice from "../../assets/axoncarenetwork/Your_Expertise,_Our_Service_Partner.webp";
import convenience from "../../assets/axoncarenetwork/convinience_Healthcare.webp";
import smarthealth from "../../assets/axoncarenetwork/smart_health.webp";
import secondOpinion from "../../assets/axoncarenetwork/secondOpinionHub.webp";
import medical from "../../assets/axoncarenetwork/pharmacy.webp";
import multispe from "../../assets/axoncarenetwork/miltispecialist_care.webp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const AxoncareNetwork = () => {
  const ourSolutionContent = [
    {
      image: globalimage,
      heading: "Make the Whole World your OPD",
      description: "Consult patients worldwide with multilingual support.",
    },
    {
      image: practise,
      heading: "AI-Assisted Practice Solution",
      description: "Reduce litigation risks with administrative assistance.",
    },
    {
      image: anytime,
      heading: "Anytime Health Kiosk",
      description:
        "Get physical examination assistance during virtual consultations.",
    },
  ];
  const labscontent = [
    {
      image: ourservice,
      heading: "Your Expertise, Our Service Partner",
      description:
        "As our diagnostic partner, provide laboratory and diagnostic services to our digimedicine patients. While we will provide integrated care to your patients seamlessly with our digimedicine platform.",
    },
    {
      image: medical,
      heading: "AxonCare Clinics within Your Centres",
      description:
        "Install Anytime Health Kiosks in designated areas within your diagnostic centres, giving patients access to specialist care. Offer all your patients the option of instant care through AxonCare clinics.",
    },
  ];

  const solutionCheckBox = [
    {
      image: global,
      heading: "Global Network",
      description:
        "Gain access to global multi-speciality expertise through our AxonCare network.",
    },
    {
      image: smarthealth,
      heading: "Smart Healthcare",
      description:
        "Our AI-powered tech solutions streamline administration and management.",
    },
    {
      image: patientFlow,
      heading: "Increased Patient Flow",
      description:
        "Tap into a worldwide pool of patients to provide your medical expertise.",
    },
  ];
  const labscardcontent = [
    {
      image: multispe,
      heading: "Multispeciality Centre",
      description:
        "Establish yourself as a multispeciality centre, offering comprehensive care under one roof with our AxonCare network of doctors.",
    },
    {
      image: secondOpinion,
      heading: "Second Opinion Hub",
      description:
        "Position your lab as a trusted source for second opinions on treatments etc. through the AxonCare global healthcare network.",
    },
    {
      image: convenience,
      heading: "Convenience Care",
      description:
        "Utilise the AxonCare app for convenient and efficient report delivery, consultation with specialists and follow-up appointments.",
    },
  ];
  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>Join AxonCare Network: Elevate Patient Care Globally</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Join the AxonCare Network and make the whole world your OPD. Benefit from AI-assisted solutions, enhance patient care, and access global multi-speciality expertise through our innovative healthcare network."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="AxonCare Network, global healthcare, multispeciality clinics, AI-assisted healthcare, Anytime Health Kiosk, join AxonCare, patient care solutions, healthcare innovation, lab and pharmacy services"
        />
      </Helmet>
      <div className=" video-section  parent">
        <div className="gif-cont container  bg-img-contain"></div>
      </div>

      <div
        className="parent same-section-parent"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
        style={{ background: "var(--white" }}
      >
        <div className="container same-section-container">
          <h2>
            {" "}
            <span>Our Solution, </span> The AxonCare Network for You!
          </h2>

          <Link to="/contact" className="cta-btn">
            Join AxonCare Network
          </Link>
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },

              600: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1150: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="same-section-swiper"
          >
            {ourSolutionContent.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                <div className="same-section-main-card    soultioncard">
                  <div
                    className="same-section-top-card bg-img-cover"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <div className="same-section-bottom-card">
                    <h2 className="solutionheading">{item.heading}</h2>
                    <p className="solutiondesc">{item.description}</p>
                    {/* <Link to="/contact" className="card-btn">Know More</Link> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <TwoCol
        image={artborad4}
        title="Join Our Network of Branded Clinics"
        span3="AxonCare"
        curious="Hospitals and Clinics"
        background={true}
        description="Elevate patient care by joining AxonCare, a global network of smart clinics. We standardise SOPs and deliver a world-class patient experience, eliminating the frustration of navigating through multiple departments and paperwork. With cutting-edge health tech and streamlined management solutions, your clinic can offer seamless healthcare and recovery. 
Become an AxonCare clinic and provide global access to quality healthcare, ensuring a smoother, more efficient experience for patients worldwide."
        buttons={[{ text: "Join AxonCare Network", path: "/contact" }]}
      />

      <div
        className="parent same-section-parent"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
        style={{ background: "var(--white" }}
      >
        <div className="container same-section-container fix-same">
          <h2>
            Why <span>Join AxonCare</span>
          </h2>

          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },

              600: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1150: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="same-section-swiper"
          >
            {solutionCheckBox.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                <div className="same-section-main-card    soultioncard">
                  <div
                    className="same-section-top-card bg-img-cover"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <div className="same-section-bottom-card">
                    <h2 className="solutionheading">{item.heading}</h2>
                    <p>{item.description}</p>
                    {/* <Link to="/contact" className="card-btn">Know More</Link> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* //////////-------------- */}

      <div
        className="parent sopd-parent 
         labsandPharma               "
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <div className="container sopd-container">
          <p
            className="curious"
            data-aos="fade-up"
            data-aos-delay="00"
            data-aos-duration="1000"
          >
            Labs and Pharmacies
          </p>

          <h3 data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">
            Elevate your <span>Lab</span> and <span>Pharmacy Services</span>
            <br /> with <span>AxonCare</span>
          </h3>
          <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            Partnering with us allows you to utilise cutting-edge technology and
            robust support to enhance patient care. Boost your visibility, drive
            patient referrals, and increase your revenue potential.
          </p>
        </div>
      </div>

      <Threecard
        cards={labscontent}
        slides={false}
        showButton={true}
        buttonText="Join AxonCare Network"
        buttonLink="/contact"
        change={true}
      />
      <div
        className="parent same-section-parent"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
        style={{ background: "var(--white" }}
      >
        <div className="container same-section-container">
          <h2 className="same-section-h2-fix">
            {" "}
            <span>Value Propositions </span> For You
          </h2>

          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },

              600: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1150: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="same-section-swiper"
          >
            {labscardcontent.map((item, index) => (
              <SwiperSlide key={index}>
                {" "}
                <div className="same-section-main-card    soultioncard">
                  <div
                    className="same-section-top-card bg-img-cover"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                  <div className="same-section-bottom-card">
                    <h2 className="solutionheading">{item.heading}</h2>
                    <p>{item.description}</p>
                    {/* <Link to="/contact" className="card-btn">Know More</Link> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default AxoncareNetwork;
