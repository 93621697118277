import React, { useRef, useEffect } from "react";
import "./Sopd.scss";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Sopd() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-quart",
    });
  }, []);

  return (
    <>
      <div
        className="parent sopd-parent 
                        "
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
       
        <div className="container sopd-container">
          <h2 data-aos="fade-up" data-aos-delay="200" data-aos-duration="600">
            World-Class Care, Anywhere, Anytime with <span><br></br> Global OPD</span>
          </h2>
          <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            No matter where in the world you reside, you can access top-tier healthcare at affordable rates! Our virtual clinics connect you with doctors worldwide in your preferred language.
          </p>
          <a href="/contact">
            <button
              className="cta-btn"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="600"
            >
              Book Appointment
            </button>
          </a>
        </div>
      </div>

      {/* -----Second opinion--- */}

      <div
        className="parent sopd-parent1  bg-img-cover"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="600"
      >
         <div className="second-opinion-overlay"></div>
        <div
          className="container sopd-container1"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="600"
        >
          <h2 className="services-title-fix">
            <span>Second Opinions</span> to Ensure Your Health Gets the Best
            Care
          </h2>
          <p>
            Just as you'd get a second opinion for your car, why not for your
            health? Whether it's cancer, tuberculosis, or chronic disease
            management, AxonCare provides expert insights on diagnoses,
            prognoses, and more from our network of specialists.
          </p>
          <a href="/contact">
            <button
              className="cta-btn"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="200"
            >
              Book Consultation
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Sopd;
